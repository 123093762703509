import React, { Component } from 'react';
import { Icon, Modal,  Form, Radio, Image, Checkbox, Loader } from 'semantic-ui-react';
import _ from 'underscore';

import utils from '../utils/utils';
import './css/login.css';

import img_visa from '../landingpage/images/payments/visa.png';
import img_mastercard from '../landingpage/images/payments/mastercard.png';
import img_paypal from '../landingpage/images/payments/paypal.png';
import img_eps from '../landingpage/images/payments/eps.png';
import img_giro from '../landingpage/images/payments/giropay.png';
import img_amex from '../landingpage/images/payments/amex.png';

import API from '../utils/resources/public/api.js';
import i18n from '../utils/i18n';

// DEFAULT PRICES IN CENT
const PRICE_6_MONTHS = 7900;
const PRICE_12_MONTHS = 8900;
const PRICE_24_MONTHS = 12700;
const PRICE_HEADSET = 18900;
const PRICE_FLIGHTCOMPUTER = 3400;
const PRICE_CHEATSHEET = 800;

class PaymentModal extends Component {
  constructor(props) {
    super(props);

    let time;
    let defaultProduct = this.props.defaultProduct;
    switch (defaultProduct) {
      case "basic":
        time = "6";
        break;
      case "platin":
        time = "24";
        break;
      default:
        time = "12";
    }

    this.state = {
      value: 'creditcard',
      time: time,
      paymentOptions: {},
      name_first: null,
      name_last: null,
      address_plz: null,
      address_street: null,
      email: null,
      confirmEmail: null,
      country: "AT",
      showDiscounts: false,
      productAndPaymentMethodSelected: false,
      shouldRenderATOLogo: false,
      products: {
        flightcomputer: false,
        headset: false,
        cheatsheet: defaultProduct === "platin" ? true : false,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.renderPayPalButton = this.renderPayPalButton.bind(this);
    this.isPurchaseViaFlightschoolAndShouldRenderLogo = this.isPurchaseViaFlightschoolAndShouldRenderLogo.bind(this);
    this.extractUrlValue = this.extractUrlValue.bind(this);
    this.getATOLogo = this.getATOLogo.bind(this);

    // Load the PayPal script when the component mounts
    this.loadPayPalScript = this.loadPayPalScript.bind(this);
    this.paypalLoaded = false;
  }
  
  componentDidMount() {
    if (!this.paypalLoaded) {
      this.loadPayPalScript();
    }
    let purchase_school_ref = utils.getCookieValue("purchase_school_ref");
    if (purchase_school_ref) {
      this.setState({ shouldRenderATOLogo: true });
      this.getATOLogo(purchase_school_ref);
    }
  }

  async getATOLogo(clientReferenceId) {
    try {
      const response = await API.getFlightschoolForPurchase({ clientReferenceId });
      if (response.logo) {
        this.setState({ 
          atoLogoURL: response.logo,
          shouldRenderATOLogo: true,
        });
      } else {
        this.setState({ shouldRenderATOLogo: false });
      }
    } catch (e) {
      this.setState({ shouldRenderATOLogo: false });
    }
  }

  loadPayPalScript() {
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=Af1D2PyIxcEXifC8grQhaWuQ7-MEdUCu9QMXz--lWkqgH05tAilLM6eh4X2NSR1maigpD09ejodasl0S&currency=EUR&locale=${i18n.getCurrentLocale()}`; // Set your client ID and locale here
    script.async = true;
    script.onload = this.renderPayPalButton;
    document.body.appendChild(script);
    this.paypalLoaded = true;
  }

  handleChange(value) {
    this.setState({ value });
  }

  handleTimeChange(time) {
    this.setState({ time });
  }

  getBorderColorClass(time) {
    if (time === this.state.time) return "green";
    return "default";
  }

  getPaymentMethodClass(value) {
    if (value === this.state.value) return "green";
    return "default";
  }

  getPriceAsIntCentForGivenMonths(months) {
    if (months === 6) return PRICE_6_MONTHS;
    if (months === 12) return PRICE_12_MONTHS;
    if (months === 24) return PRICE_24_MONTHS;
  }

  getFinalPriceAsHumanReadableString(months) {
    var price = this.getFinalPrice(months);
    return "€" + price;
  }

  getPriceForCheatSheet(months) {
    if (months === 24) return 0;
    return PRICE_CHEATSHEET;
  }

  getReadablePriceForCheatSheet() {
    if (this.state.time === "24") return i18n("free_of_charge");
    return "€" + PRICE_CHEATSHEET / 100 + ",--";
  }

  getFinalPrice(months) {
    var price = this.getPriceAsIntCentForGivenMonths(months);
    if (this.state.products["headset"]) {
      price += PRICE_HEADSET;
    } 
    if (this.state.products["flightcomputer"]) {
      price += PRICE_FLIGHTCOMPUTER;
    }
    if (this.state.products["cheatsheet"]) {
      price += this.getPriceForCheatSheet(months);
    }
    price = price / 100;
    return price;
  }

  getPrice() {
    const time = this.state.time;
    if (time === "12") {
      return this.getFinalPriceAsHumanReadableString(12)
    } else if (time === "24") {
      return this.getFinalPriceAsHumanReadableString(24)
    }
    return this.getFinalPriceAsHumanReadableString(6)
  }

  getTotalPrice() {
    const time = this.state.time;
    if (time === "12") {
      return this.getFinalPrice(12);
    } else if (time === "24") {
      return this.getFinalPrice(24);
    }
    return this.getFinalPrice(6);
  }

  nextStep() {
    const that = this;
    const state = {
      productAndPaymentMethodSelected: true
    };
    if (this.props.defaultProduct === "package") {
      state.products = {
        flightcomputer: true,
        headset: true
      };
    }
    if (this.state.time === "24") {
      state.products = _.extend(state.products || {}, { cheatsheet: true });
    }
    this.setState(state, () => {
      window.setTimeout(function () {
        that.renderPayPalButton();
      }, 200);
    });

  }

  updateProducts(product) {
    var that = this;
    var products = this.state.products;
    var val = !this.state.products[product];
    products[product] = val;
    this.setState({ 
      products,
    }, () => {
      this.forceUpdate()
      window.setTimeout(function () {
        that.renderPayPalButton();
      }, 200);
    });
  }

  extractUrlValue(key, string) {
    const match = string.match('[?&]' + key + '=([^&]+)');
    return match ? match[1] : null;
  }

  renderPayPalButton() {
    let purchase_school_ref = utils.getCookieValue("purchase_school_ref");
    const that = this;
    if (window.paypal) {
      const container = document.getElementById('paypal-button-container');
      if (container) { 
        container.innerHTML = '';
        window.paypal.Buttons({
          style: {
            layout: 'horizontal',
            label: 'pay',
            height: 50,
            shape: 'rect',   
          },
          createOrder: (data, actions) => {
            const language = document.documentElement.lang;
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: this.getTotalPrice(),
                },
                custom_data: JSON.stringify({
                  purchase_school_ref,
                  language,
                })
              }],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then(details => {
              that.props.onPayPalSuccess(details);
            });
          },
          onError: (err) => {
            that.props.onPayPalError(err);
          },
          onCancel: (data) => {
            this.props.onPayPalCancel(data);
          },
        }).render('#paypal-button-container');
      }
    }
  }

  isPurchaseViaFlightschoolAndShouldRenderLogo() {
    if ((this.state.productAndPaymentMethodSelected)) return false;

    if (this.state.shouldRenderATOLogo) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div>
        <Modal className="payment-modal" dimmer="inverted" size="small" open={true} onClose={this.props.onAbort} closeOnEscape={true} closeOnDimmerClick={false}>
        <Modal.Header>{i18n("buy_ppltrainer")}</Modal.Header>
        <div className="payment-total-price-header">{i18n("totalPrice")}: {this.getPrice()}</div>
        <Modal.Content className={`payment ${this.state.productAndPaymentMethodSelected ? "summarize-payment": ""}`}>
          <Modal.Description>
          <div className="select-account-duration-container">
          < Form>
            <p><b className="fs-13">{i18n("runtime")}:</b> <span className='runtime-description'>{i18n("runtime_description")}</span></p>
              <Form.Field className={`half-width border-color-${this.getBorderColorClass("6")}`}>
                <Radio
                  label={i18n("month_6")}
                  name='time'
                  value='6'
                  checked={this.state.time === '6'}
                  onChange={e => this.handleTimeChange('6', e)}
                />
              </Form.Field>
              <Form.Field className={`half-width border-color-${this.getBorderColorClass("12")}`}>
                <Radio
                  label={i18n("month_12")}
                  name='time'
                  value='12'
                  checked={this.state.time === '12'}
                  onChange={e => this.handleTimeChange('12', e)}
                />
              </Form.Field>
              <Form.Field className={`half-width border-color-${this.getBorderColorClass("24")}`}>
                <Radio
                  label={i18n("month_24")}
                  name='time'
                  value='24'
                  checked={this.state.time === '24'}
                  onChange={e => this.handleTimeChange('24', e)}
                />
              </Form.Field>
            </Form>
          </div>
          < Form className="payment-container-selection">
            <p><b className="fs-13">{i18n("paymentmethod")}:</b> <span className='runtime-description'>{i18n("paymentmethod_description")}</span></p>
              <Form.Field className={`border-color-${this.getPaymentMethodClass("creditcard")}`}>
                <Radio
                  label={i18n("creditcard")}
                  name='radioGroup'
                  value='creditcard'
                  checked={this.state.value === 'creditcard'}
                  onChange={e => this.handleChange('creditcard', e)}
                />
                <div className="payment-overview-pay">
                  <img className="card-pay" src={img_visa} alt="Visa" />
                  <img className="card-pay" src={img_mastercard} alt="Mastercard" />
                  <img className="card-pay" src={img_amex} alt="American Express" />
                </div>
              </Form.Field>

              {<Form.Field className={`border-color-${this.getPaymentMethodClass("epsgiro")}`}>
                <Radio
                  label='EPS | Giropay'
                  name='radioGroup'
                  value='epsgiro'
                  checked={this.state.value === 'epsgiro'}
                  onChange={e => this.handleChange('epsgiro', e)}
                />
                <div className="payment-overview-pay">
                  <img className="card-pay" src={img_eps} alt="EPS" />
                  <img className="card-pay" src={img_giro} alt="Giropay" />
                </div>
              </Form.Field>}

              <Form.Field className={`border-color-${this.getPaymentMethodClass("paypal")}`}>
                <Radio
                  label='PayPal'
                  name='radioGroup'
                  value='paypal'
                  checked={this.state.value === 'paypal'}
                  onChange={e => this.handleChange('paypal', e)}
                />
                <div className="payment-overview-pay">
                  <img className="card-pay" src={img_paypal} alt="PayPay" />
                </div>
              </Form.Field>
            </Form>

            {
              // initial screen (payment + product selection)
              this.state.productAndPaymentMethodSelected ? 
              <div className="discount-container-payment">
                
                <span className="discount-header">{i18n("recomanded_for_you")}:</span> 

                <div className="discount-container-parent-wrapper">
                  <div className="discount-container-inner-wrapper">
                    <div className={`discount-container-inner ${this.state.products["flightcomputer"] ? "active": ""}`} onClick={e => this.updateProducts("flightcomputer")}>
                      <div className="price-sign">€34,--</div>
                      <Image src="/img/flightcomputer.jpg" className="promo-image img-placeholder-product" />
                      <div className='confirm-include-container'>
                        <b>{i18n("e6b_flightcomputer")}</b>
                        <Checkbox checked={this.state.products["flightcomputer"]} label={i18n("confirm-include-to-cart")} />
                      </div>
                    </div>
                  </div>

                  <div className="discount-container-inner-wrapper pad-fix">
                    <div className={`discount-container-inner ${this.state.products["headset"] ? "active": ""}`} onClick={e => this.updateProducts("headset")}>
                      <div className="price-sign">€189,--</div>
                      <Image src="/img/headset.jpg" className="promo-image img-placeholder-product" />
                      <div className='confirm-include-container'>
                        <b>{i18n("aviation_headset")}</b>
                        <Checkbox checked={this.state.products["headset"]} label={i18n("confirm-include-to-cart")} />
                      </div>
                    </div>
                  </div>

                  <div className="discount-container-inner-wrapper pad-fix">
                    <div className={`discount-container-inner ${this.state.products["cheatsheet"] ? "active": ""}`} onClick={e => this.updateProducts("cheatsheet")}>
                      <div className="price-sign">{this.getReadablePriceForCheatSheet()}</div>
                      <Image src="/img/cheat_sheet.jpg" className="promo-image img-placeholder-product cheat-sheet-img" />
                      <div className='confirm-include-container'>
                        <b>Cheat Sheet</b>
                        <Checkbox checked={this.state.products["cheatsheet"]} label={i18n("confirm-include-to-cart")} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`discount-container-free-items ${this.state.products["headset"] || this.state.products["flightcomputer"] ? "active": ""}`}>
                  <Checkbox checked={this.state.products["headset"] || this.state.products["flightcomputer"]} className="confirm-include-free-items" label="" />
                  <ul>
                    <li>-<b className="high-size">{i18n("free_shipping")}</b></li>
                    <li>-<b className="high-size">{i18n("free_navigationtriangle")}</b></li>
                    <li>-<b className="high-size">{i18n("free_key")}</b></li>
                  </ul>
                  <Image src="/img/free-items.jpg" className="promo-image promo-image-free" />
                </div>

                <div className="ruecktritt-container">
                {i18n("ruecktrittsrecht")}
                </div>
                {
                  this.state.value === 'creditcard' || this.state.value === 'epsgiro' ?
                  <button onClick={e => this.props.onSelect(this.state.value, this.state.time, this.state.products, e)} className="button button-primary btn-checkout">{i18n("nextStep")} <Icon name="arrow right"/></button>
                  : this.state.value === 'paypal' ?
                  <div className="paypal-checkout-btn">
                    <div id="paypal-button-container"></div>
                  </div>
                  : null
                }
              </div>
              :
              <button onClick={e => this.nextStep()} className="button button-primary btn-checkout">{i18n("nextStep")} <Icon name="arrow right"/></button>
            }

            { 
              this.isPurchaseViaFlightschoolAndShouldRenderLogo() &&
              <div className='payment-flightschool-information'>
                {
                  this.state.atoLogoURL ? 
                    <img className="" src={this.state.atoLogoURL} alt="ATO-Logo" />  
                  : 
                    <Loader active inline='centered' />
                }
              </div>
            }

          </Modal.Description>
        </Modal.Content>
      </Modal>
     </div>
    );
  }
}

export default PaymentModal;

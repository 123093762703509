import React, { Component } from 'react';
import _ from 'underscore';
import API from '../../../utils/resources/ato.js';
import i18n from "../../../utils/i18n";

import { Modal, Icon } from 'semantic-ui-react';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class StudentFormular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: this.props.formularOptions && this.props.formularOptions.student,
      exams: null,
      homework: null,
      userExams: null,
      showAllHomeworks: false,
      showAllExams: false,
      showAllUserExams: false,
    };
    this.isReady = this.isReady.bind(this);
    this.hasNotPassed = this.hasNotPassed.bind(this);
    this.parseCustomDate = this.parseCustomDate.bind(this);
  }

  componentDidMount() {
    const that = this;
    const userID = this.state.student && this.state.student.email;
    API.getStudentInsights(userID).then((studentData) => {
      let { exams, favoriteQuestions, homework, userExams } = studentData;
      if (exams) {
        that.setState({ exams });
      }
      if (userExams) {
        userExams = userExams && userExams.exams && JSON.parse(userExams.exams);
        _.each(userExams, function (exam, index) { exam.id = index; })
        that.setState({ userExams });
      }
      if (favoriteQuestions) {
        that.setState({ favoriteQuestions });
      }
      if (homework) {
        that.setState({ homework });
      }
    });
  }

  downloadItem(id, type) {
    const items = this.state[type];
    const selectedItem = items[id];
    if (!selectedItem) return;
    pdfMake.createPdf(selectedItem.dd).download("ppltrainer-"+ type +"-"+ selectedItem.date +".pdf");
  }

  isReady() {
    // TODO
    return true;
  }

  hasNotPassed(percent, exam) {
    let status_of_work = exam.status_of_work;
    if (status_of_work) {
      status_of_work = status_of_work === "not_passed";
    } else if (exam.globalResult) {
      status_of_work = exam.globalResult === "Not Passed"; // used as fallback key in this case
    } else {
      status_of_work = percent < 75;
    }
    return status_of_work;
  }

  getPathColor(percent, exam) {
    if (this.hasNotPassed(percent, exam)) return "#d9534f"; // red
    return "#5cb85c"; // green
  }

  getBackgroundColorClassForExams(percent, exam) {
    if (this.hasNotPassed(percent, exam)) return "bg-red"; // red
    return "bg-green"; // green
  }

  getPercentValueOfExam(eaxm) {
    const categories = eaxm.dd.content[5].table.body;
    let count = 0;
    _.each(categories, function(category) {
      let value = category[2];
      if (_.isObject(value)) { value = value.text; }
      value = value.split("%")[0];
      count += parseFloat(value);
    });
    return parseInt(count / categories.length);
  }

  parseWithFormat(dateString, format) {
    dateString = dateString.split(",")[0]; // e.g. format is 25.09.2023, 20:15
    const parts = dateString.split(/[./]/);
    const [day, month, year] = parts.map(part => parseInt(part, 10));
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return null;
    }
    return new Date(`${year}-${month}-${day}`);
  }

  parseCustomDate(dateString) {
    const that = this;
    const formats = ["DD.MM.YYYY", "DD.M.YYYY", "DD/MM/YYYY"];
    _.each(formats, (format) => {
      const parsedDate = that.parseWithFormat(dateString, format);
      if (parsedDate) {
        return parsedDate;
      }
    });
    return null;
  }

  render() {
    const that = this;
    if (!this.isReady()) return "";

    // sorted exams by date
    const examsArrayEx = this.state.exams && Object.values(this.state.exams);
    let sortedExams = [];
    if (examsArrayEx) {
      sortedExams = examsArrayEx.sort((a, b) => {
        const dateA = that.parseCustomDate(a.date);
        const dateB = that.parseCustomDate(b.date);
        return dateB - dateA;
      });
    }

    // sorted homework by date
    const examsArrayHw = this.state.homework && Object.values(this.state.homework);
    let sortedHomeworks = [];
    if (examsArrayHw) {
      sortedHomeworks = examsArrayHw.sort((a, b) => {
        const dateA = that.parseCustomDate(a.date);
        const dateB = that.parseCustomDate(b.date);
        return dateB - dateA;
      });
    }

    // sorted homework by date
    let sortedUserExams = [];
    if (this.state.userExams) {
      sortedUserExams = this.state.userExams.sort((a, b) => {
        const dateA = that.parseCustomDate(a.date);
        const dateB = that.parseCustomDate(b.date);
        return dateB - dateA;
      });
    }

    const renderLimitedItems = (items, showAll, maxItems, type) => {
      const visibleItems = showAll ? items : items.slice(0, maxItems);
    
      return _.map(visibleItems, function (item, id) {
        if (typeof item.dd === 'string') {
          item.dd = JSON.parse(item.dd);
        }

        const { date, firstname, lastname, description } = item;

        let displayName = `${firstname} ${lastname}`;
        if (description) {
          displayName += ` (${description})`;
        }

        const value = that.getPercentValueOfExam(item);
        return (
          <div className={`exam-container ${that.getBackgroundColorClassForExams(value, item)}`} key={id}>
            <div className='fbf'>
              <CircularProgressbarWithChildren
                className='circle-exams'
                value={value}
                text={value}
                strokeWidth="15"
                styles={buildStyles({ textColor: that.getPathColor(value, item), pathColor: that.getPathColor(value, item), trailColor: "#e4e4e4" })}
                onClick={() => that.downloadItem(item.id, type)}
              ></CircularProgressbarWithChildren>
            </div>
            <div className='fbl'>
              <div>{displayName}, <b>{date}</b></div>
              <div className='exam-container-actions'>
                <div className="exam-action-item" onClick={() => that.downloadItem(item.id, type)}><Icon name='download' /> {i18n("download")}</div>
              </div>
            </div>
          </div>
        );
      });
    };

    const MAX_EXAMS_AND_HOMEWORKS_BEFORE_SHOW_MORE = 3;

    return (
      <Modal.Content scrolling className="mm-content-container">
        <Modal.Header>{i18n("student_details")}</Modal.Header>
        <Modal.Description>
          <div>
            <div className='mm-description-item'>{i18n("student_insights")} <b>{this.state.student && this.state.student.name}</b></div>
          </div>
        </Modal.Description>
        <div className='mm-form-container'>
          <div className='mm-user-exams-container mm-user-homework-container'>
            <div className='mm-form-headline-description'>{i18n("current_homework")}</div>
            {_.isEmpty(this.state.homework) && <div>{i18n("no_homework_available")}</div>}
            {
              renderLimitedItems(sortedHomeworks, this.state.showAllHomeworks, MAX_EXAMS_AND_HOMEWORKS_BEFORE_SHOW_MORE, "homework")
            }
            {sortedHomeworks.length > MAX_EXAMS_AND_HOMEWORKS_BEFORE_SHOW_MORE && !this.state.showAllHomeworks && (
              <button className="btn-second" onClick={() => this.setState({ showAllHomeworks: true })}>{i18n('all_homework')}</button>
            )}
          </div>
          <div className='mm-user-exams-container'>
            <div className='mm-form-headline-description'>{i18n("exams")}</div>
            {_.isEmpty(this.state.exams) && <div>{i18n("no_exams_available")}</div>}
            {
              renderLimitedItems(sortedExams, this.state.showAllExams, MAX_EXAMS_AND_HOMEWORKS_BEFORE_SHOW_MORE, "exams")
            }
            {sortedExams.length > MAX_EXAMS_AND_HOMEWORKS_BEFORE_SHOW_MORE && !this.state.showAllExams && (
              <button className="btn-second" onClick={() => this.setState({ showAllExams: true })}>{i18n('all_exams')}</button>
            )}
          </div>
          <div className='mm-user-exams-container'>
            <div className='mm-form-headline-description'>{i18n("own_exams")}</div>
            {_.isEmpty(this.state.userExams) && <div>{i18n("no_own_exams_available")}</div>}
            {
              renderLimitedItems(sortedUserExams, this.state.showAllUserExams, MAX_EXAMS_AND_HOMEWORKS_BEFORE_SHOW_MORE, "userExams")
            }
            {sortedUserExams.length > MAX_EXAMS_AND_HOMEWORKS_BEFORE_SHOW_MORE && !this.state.showAllUserExams && (
              <button className="btn-second" onClick={() => this.setState({ showAllUserExams: true })}>{i18n('all_own_exams')}</button>
            )}
          </div>
          <div className='mm-user-exams-container saved-questions-container'>
            <div className='mm-form-headline-description'>{i18n("saved_questions")}</div>
            {_.isEmpty(this.state.favoriteQuestions) && <div>{i18n("no_saved_questions_available")}</div>}
            {
              _.map(this.state.favoriteQuestions, function (question, key) {
                return (
                  <div className='exam-container' key={key}>
                    <div className='exam-container-info' >
                      <div>{question} <small>({key})</small></div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal.Content>
    );
  }
}

export default StudentFormular;

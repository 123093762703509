import React, { Component } from 'react';
import { Select, Table } from 'semantic-ui-react';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import _ from 'underscore';
import { Icon } from 'semantic-ui-react'
import i18n from "../utils/i18n";
import TableList from '../components/manager/TableList';
import API from '../utils/resources/ato.js';
import UserAPI from '../utils/resources/api.js';

class ManageAnswers extends Component {
  constructor(props) {
    super(props);
    this.quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['clean']
      ]
    };

    this.quillFormats = [
      'bold', 'italic', 'underline', 'strike',
      'list', 'bullet',
    ];

    this.state = {
      answers: [],
      analytics: [],
      searchTerm: '',
      foundedElements: [],
      questionID: null,
      question: null,
      answer: null,
      customText: '',
      language: document.documentElement.lang,
    };

    this.onSearch = this.onSearch.bind(this);
    this.collectAnswers = this.collectAnswers.bind(this);
    this.update = this.update.bind(this);
    this.selectQuestion = this.selectQuestion.bind(this);
    this.afterCreateAnswer = this.afterCreateAnswer.bind(this);
    this.editPublicAnswer = this.editPublicAnswer.bind(this);
    this.onSave = this.onSave.bind(this);
    this.suggestExplanation = this.suggestExplanation.bind(this);
    this.deletePublicAnswer = this.deletePublicAnswer.bind(this);
    this.getRandomQuestion = this.getRandomQuestion.bind(this);
  }

  componentDidMount() {
    this.fetchAnswers();
    this.fetchAnalytics();
  }

  fetchAnswers() {
    const that = this;
    const data = API.getPublicAnswers();
    data.then(function(response) {
      const { answers } = response;
      that.setState({
        answers,
      })
    });
  }

  fetchAnalytics() {
    const that = this;
    const data = API.getAnswersAnalytics();
    data.then(function(analytics) {
      that.setState({
        analytics,
      })
    });
  }

  collectAnswers(item) {
    const answers = [];
    const correctAnswer = item[item["Correct"]];
    answers.push(item["A"]);
    answers.push(item["B"]);
    answers.push(item["C"]);
    answers.push(item["D"]);
    const index = answers.indexOf(correctAnswer);
    if (index !== -1) {
      answers.splice(index, 1);
      answers.unshift(correctAnswer);
    }
    return answers;
  }

  getLangOptions() {
    return _.map(['de','en','sk','nl','ro','cs','sl','fr','fi','is','es'], function(lang, index) {
      return { key: lang, value: lang, text: i18n(lang) };
    });
  }

  onSearch(options) {
    const { value } = options.target;
    this.setState({ searchTerm: value });
    this.fetchData(value);
  }

  getFlatQuestionKeysInGivenLanguage() {
    const lang = document.documentElement.lang;
    return (this.state.answers &&  this.state.answers[lang] && Object.keys(this.state.answers[lang])) || []; 
  }

  fetchData(term) {
    const foundedElements = [];
    const flatQuestionKeysInGivenLanguage = this.getFlatQuestionKeysInGivenLanguage();
    _.each(this.props.questions, (questionObj) => {
      _.each(questionObj, (item) => {
        _.each(item.data, function(element) {
          let question = element.question || element["Question"] || "";
          let questionID = element["Question ID"] || element["key"] || "";
          let questionItem = question.match(new RegExp(term, 'i')) || questionID.match(new RegExp(term, 'i'));
          if (flatQuestionKeysInGivenLanguage.indexOf(questionID) === -1 && questionItem) {
            foundedElements.push(element);
          }
        });
      });
    });

    this.setState({ foundedElements });
  }

  selectQuestion(questionID, question, answer, answers, customText) {
    this.setState({
      foundedElements: [],
      searchTerm: '',
      questionID,
      question,
      answer,
      answers,
      customText: customText || '',
    });
  }

  update(options, data) {
    let name = options.target ? options.target.name : (data && data.name);
    let value = options.target ? options.target.value : (data && data.value);

    // Handle Quill content
    if (typeof options === 'string') {
      name = 'customText';
      value = options;
    }

    if (options.target && options.target.files) {
      const file = options.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      name = "image";
      value = formData;
    }

    const obj = {};
    obj[name] = value;
    this.setState(obj);
  }

  onSave() {
    const { customText, image, questionID, question, answer, language} = this.state;
    if (!customText) return;

    const newAnswer = {
      "answer": { 
        customText,
        image,
        id: questionID,
        question: question,
        answer: answer,
        language: language,
      }
    };

    this.createNewAnswer(newAnswer);
  }

  imageToUpload(data) {
    if (data && data.answer && data.answer.image instanceof FormData) return true;
    return false;
  }

  createNewAnswer(data) {
    const that = this;
    const endpoint = 'createPublicAnswer';
    if (this.imageToUpload(data)) {
      const image = data.answer.image; 
      API.imageUpload(image).then((imageURL) => {
        if (data.answer) {
          data.answer.image = imageURL;
        }
        const image = imageURL[0];
        data.image = image;
        that.saveData(endpoint, data);
      });
    } else {
      this.saveData(endpoint, data);
    }
  }

  saveData(endpoint, data, options) {
    options = options || {};
    const that = this;
    API[endpoint](data).then(() => {
      that.fetchData();
      that.afterCreateAnswer();
    })
  }

  afterCreateAnswer() {
    this.setState({
      searchTerm: '',
      foundedElements: [],
      questionID: null,
      question: null,
      answer: null,
      customText: '',
    });
    this.fetchAnswers(); 
  }

  toggleAnswerLangVisibility(lang) {
    this.setState((prevState) => ({
      openAnswerLanguage: prevState.openAnswerLanguage === lang ? null : lang,
    }));
  }

  deletePublicAnswer(answer) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_answer"))) {
      API.deletePublicAnswer(answer).then((answer) => {
        that.fetchAnswers(); 
      });
    }
  }

  editPublicAnswer(answerObj) {
    const { id, question, answer, customText } = answerObj;
    const questionID = id;
    const answers = [];
    // open overlay with answer
    this.selectQuestion(questionID, question, answer, answers, customText);
  }

  sanitizeContent = (htmlContent) => {
    if (!htmlContent) return "";
    const cleanHtml = DOMPurify.sanitize(htmlContent, {
      ALLOWED_TAGS: ['b', 'i', 'u', 'strong', 'ol', 'ul', 'li', 'p', 'br', 'em', 's'],
      ALLOWED_ATTR: {}
    });
    return cleanHtml;
  };

  renderAvailableAnswers() {
    const that = this;
    if (_.isEmpty(this.state.answers)) return null;
    let count = 0;
    _.each(this.state.answers, function (answer) { count += Object.keys(answer).length; });
    if (count === 0) return null;

    return Object.keys(this.state.answers).map((category) => {
      const answersInLanguageKey = this.state.answers[category];
      const answerCount = Object.keys(answersInLanguageKey).length;
      const thisCategoryIsOpen = this.state.openAnswerLanguage === category;
      const accordionIcon = thisCategoryIsOpen ? "triangle up" : "triangle down";
      return (
        <React.Fragment key={category}>
          <Table.Row>
            <Table.Cell colSpan="5" className="collapsible-item" onClick={() => this.toggleAnswerLangVisibility(category)}>
              {i18n(category) || category} <span>({answerCount})</span> <Icon name={accordionIcon} />
            </Table.Cell>
          </Table.Row>
          {this.state.openAnswerLanguage === category && Object.keys(answersInLanguageKey).map((entry, index) => {
            const id = entry;
            entry = answersInLanguageKey[entry];
            const { customText, question, image } = entry;
            const sanitizedContent = this.sanitizeContent(customText);
            return (
              <Table.Row key={index}>
                <Table.Cell className="id-field-item" title={id}>
                  {id}
                </Table.Cell>
                <Table.Cell className="dyn-width" title={question}>{question}</Table.Cell>
                <Table.Cell className="dyn-width">
                  <div 
                    className='ai-content-text'
                    dangerouslySetInnerHTML={{ __html: sanitizedContent }} 
                  />
                </Table.Cell>
                <Table.Cell className='answers dyn-width'>
                  {image ? <img className='custom-image-preview float-left' src={image} alt='custom' /> : null}
                </Table.Cell>
                <Table.Cell className="dyn-width action-content action-content-question action-field-item-answers">
                  <div className="single-action" onClick={() => that.deletePublicAnswer(entry)}>{i18n('delete')}</div>
                  <div className="single-action" onClick={() => that.editPublicAnswer(entry)}>{i18n('edit')}</div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </React.Fragment>
      );
    });
  }

  renderAnalyticsData() {
    if (!this.state.analytics || this.state.analytics.length === 0) return null;
    return this.state.analytics.map((entry, index) => {
      const { key, count } = entry;
      return (
        <Table.Row key={index}>
          <Table.Cell className="id-field-item">{key}</Table.Cell>
          <Table.Cell>{count}</Table.Cell>
        </Table.Row>
      );
    });
  }

  getRandomQuestion() {
    const allQuestions = [];
    
    _.each(this.props.questions.data, (category) => {
      _.each(category.data, (questionData) => {
        allQuestions.push(questionData);
      });
    });
  
    _.each(this.props.questions.older_questions, (category) => {
      _.each(category.data, (questionData) => {
        allQuestions.push(questionData);
      });
    });
  
    const lang = this.state.language;
    const answeredQuestions = Object.keys(this.state.answers[lang] || {});
  
    const unansweredQuestions = allQuestions.filter((question) => {
      return !answeredQuestions.includes(question["Question ID"] || question.key);
    });
  
    if (unansweredQuestions.length > 0) {
      const randomIndex = Math.floor(Math.random() * unansweredQuestions.length);
      const randomQuestion = unansweredQuestions[randomIndex];
  
      const correctAnswerIndex = randomQuestion["correctAnswer"] || 0;
      const answers = randomQuestion.answers || this.collectAnswers(randomQuestion);
      const correctAnswer = answers[correctAnswerIndex];
  
      this.setState({
        questionID: randomQuestion["Question ID"] || randomQuestion.key,
        question: randomQuestion.question || randomQuestion.Question,
        answer: correctAnswer,
        answers: randomQuestion["answers"],
        customText: '',
      });
    } else {
      console.log("Alle Fragen wurden bereits beantwortet!");
    }
  }   

  suggestExplanation() {
    const that = this;
    const endpoint = "suggestAIAnswer";
    const data = {
      "answer": this.state.answer,
      "id": this.state.questionID,
      "language": this.state.language,
      "question": this.state.question,
      "answers": this.state.answers
    };
    UserAPI[endpoint](data).then((response) => {
      that.setState({
        customText: (response && response.content) || "",
      })
    })
  }
  
  render() {
    const that = this;
    return <div className='manage-flightsschool-container Overview clearfix manage-answers-component'>
      <div className='header-information'>
        <label><b>{i18n('all_question_explanations')}</b></label>
      </div>
      <div className='manage-flightschool-inner-container create-flightschool-container'>

      <div className='fbc'>
        <button className='btn-second fbf' onClick={this.props.onBack}>{i18n("back")}</button>
      </div>

      <div className='mng-headline'>
        <label>{i18n('manage_answers')}</label>
        <span>{i18n('manage_answers_description')}</span>
      </div>

      {
        !this.state.questionID ?
        <>
          <div className={`fbl mm-search input-field-new-answer`}>
            <input type="search" value={this.state.searchTerm} placeholder={i18n("search_questions")} onChange={this.onSearch} />
            <Icon name="search" title={i18n("search")} />
          </div>

          <button className='btn btn-default mb-15' onClick={this.getRandomQuestion}>{i18n("answer_random_question")}</button>

          <div className='result-content set-new-answer-assistance'>
            <ul>
              {
                this.state.searchTerm && 
                this.state.searchTerm.length > 2 && 
                  _.map(this.state.foundedElements, function (item, index) {
                    let question = item.question || item["Question"] || "";
                    let questionID = item["Question ID"] || item["key"] || index;
                    let readableKey = item["Question ID"] || i18n('flightschool');
                    const correctAnswerIndex = item["correctAnswer"] || 0;
                    const answers = item.answers || that.collectAnswers(item);
                    const correctAnswer = answers[correctAnswerIndex];
                    return <li key={index + "_" + questionID} className="question-answer-item" onClick={() => that.selectQuestion(questionID, question, correctAnswer, answers)}>
                      <div className='question-item'>{question} <small>({readableKey})</small></div>
                      <div className='answers-containers correctAnswer'>{correctAnswer}</div>
                    </li>;
                  })
              }
            </ul>
          </div>

          {
            (!this.state.searchTerm ||
            this.state.searchTerm.length < 2) && 
            <>
            <TableList
              tableClass="fixed collapsing fullWidth"
              tableHeaderRow={
                <Table.Row>
                  <Table.HeaderCell className="id-field-item">{i18n('id_number')} <Icon className='question-category-icon-fix' name="sort down" /></Table.HeaderCell>
                  <Table.HeaderCell>{i18n('question')}</Table.HeaderCell>
                  <Table.HeaderCell>{i18n('explanation')}</Table.HeaderCell>
                  <Table.HeaderCell className="action-field-item-answers">{i18n('image')}</Table.HeaderCell>
                  <Table.HeaderCell className="dyn-width action-content action-content-question action-field-item-answers">{i18n('actions')}</Table.HeaderCell>
                </Table.Row>
              }
              tableBody={this.renderAvailableAnswers()}
              emptyDataValue={i18n('no_answers_available')}
            />
            <div className='mb-20'></div>
            <TableList
              tableClass="fixed collapsing fullWidth"
              tableHeaderRow={
                <Table.Row>
                  <Table.HeaderCell className="id-field-item">{i18n('id_number')}</Table.HeaderCell>
                  <Table.HeaderCell>{i18n('number')}</Table.HeaderCell>
                 </Table.Row>
              }
              tableBody={this.renderAnalyticsData()}
              emptyDataValue={i18n('not_available')}
            />
            </>
          }
        </>
        :
        <>
          <div className='question-answer-summary'>
            <div className='question-summary'>{this.state.question}</div>
            <div className='correctAnswer'>{this.state.answer}</div>
            <button className="btn-second" onClick={() => this.suggestExplanation()}>
              {i18n("suggest_explanation")}
            </button>
            
            {/* React Quill Editor */}
            <div className="mt-5">
              <ReactQuill
                theme="snow"
                value={this.state.customText}
                onChange={(content) => this.update(content)}
                modules={this.quillModules}
                formats={this.quillFormats}
                placeholder={i18n("enter_explanation")}
              />
            </div>

            <Select 
              className="mt-5 select-lang-from-for-answers" 
              value={this.state.language} 
              placeholder={i18n('language')} 
              name="language" 
              options={this.getLangOptions()} 
              onChange={(options, data) => this.update(options, data)} 
            />

            <input 
              className='image-input' 
              type="file" 
              lang={document.documentElement.lang} 
              accept="image/*" 
              id="image" 
              placeholder={i18n("image")} 
              name="image" 
              onChange={(options) => this.update(options)}
            />

            <button 
              className='btn btn-primary' 
              onClick={() => that.onSave()}>
              {i18n('add_new_answer_explanation')}
            </button>
          </div>
        </>
      }

      </div>
    </div>
  }
}

export default ManageAnswers;
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

import image from './img/copilot.png';

import i18n from "../utils/i18n";

import './css/Assistant.css';

class Skeleton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      disabled: true,
      feedback_A_value: undefined,
    };

    this.openDialog = this.openDialog.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getGivenAnswerPosition = this.getGivenAnswerPosition.bind(this);
    this.getGivenAnswerUser = this.getGivenAnswerUser.bind(this);
    this.shouldShowFeedbackPossibility = this.shouldShowFeedbackPossibility.bind(this);
    this.getTitleForUserInteraction = this.getTitleForUserInteraction.bind(this);
  }

  openDialog() {
    this.props.onFeedbackDialogOpen();
  }

  closeDialog() {
    this.setState({
      disabled: true,
      feedback_A_value: undefined,
    });
    this.props.onFeedbackDialogClose();
  }

  sendFeedback() {
    this.props.sendFeedback({
      rating: this.state.feedback_A_value,
      userText: this.state.text,
    });
  }

  handleChange(value) {
    this.setState({
      disabled: false,
      feedback_A_value: value,
    });
  }

  getGivenAnswerUser() {
    const answerOrigin = this.props.showAIDisclaimer;
    if (answerOrigin === "AI") return "PPL Trainer Copilot";
    if (answerOrigin === "ATO") return i18n("your_flightschool");
    return "Charlie";
  }

  getGivenAnswerPosition() {
    const answerOrigin = this.props.showAIDisclaimer;
    if (answerOrigin === "AI") return i18n("content_is_generated_by_ai")
    return <><Icon className='approved-answer-icon' name="check circle" /> {i18n("approved_answer")}</>;
  }

  shouldShowFeedbackPossibility() {
    return true;
    /* if (this.props.showAIDisclaimer === "AI") {
      return true;
    } */
  }

  getTitleForUserInteraction() {
    if (this.props.showAIDisclaimer === "AI") {
      return i18n('content_is_generated_by_ai');
    } 
    return i18n('content_has_been_approved');
  }

  render() {
    const { open, success } = this.props;
    return (
      <div className='ai-content-feedback'>

        <div className="user-content-container" title={this.getTitleForUserInteraction()}>
          <div className="user-info">
            <img className="user-icon" src={`${image}`} alt="User Icon" />
            <div className="user-name">
              <span><b>{this.getGivenAnswerUser()}</b></span>
              <span><i>{this.getGivenAnswerPosition()}</i></span>
            </div>
          </div>
        </div>

        {
          this.shouldShowFeedbackPossibility() && 
          <div className='rating-container'>
            {
              open &&
              <div className='ai-modal'>
                <div className='ai-modal-content'>
                  <div className='ai-modal-title'>{i18n('feedback_for_ai_content')}</div>
                  <small>{i18n('was_the_ai_content_helpful')}</small>
                  <div className='ai-feedback-rating'>
                    <div className='ai-feedback-rating-item'>

                      <label onClick={() => this.handleChange('good')} className={`good-box ${this.state.feedback_A_value === 'good' ? 'selected': ''}`}>
                        <Icon name="smile outline" />
                      </label>

                      <label onClick={() => this.handleChange('meh')} className={`meh-box ${this.state.feedback_A_value === 'meh' ? 'selected': ''}`}>
                        <Icon name="meh outline" />
                      </label>

                      <label onClick={() => this.handleChange('frown')} className={`frown-box ${this.state.feedback_A_value === 'frown' ? 'selected': ''}`}>
                        <Icon name="frown outline" />
                      </label>

                    </div>
                  </div>
                  {
                    this.state.feedback_A_value &&
                      <div className='additional-ai-feedback-container'>
                        <small className='additional-ai-feedback-container-headline'>
                        { this.state.feedback_A_value === "good" ? i18n('good-feedback-response') : i18n('meh-frown-feedback-response') }
                        </small>
                        <textarea value={this.text} onChange={(event) => this.setState({ text: event.target.value })}></textarea>
                      </div>
                  }
                </div>
                <div className='ai-modal-actions'>
                  <button className='btn-second' onClick={() => this.closeDialog() }>{i18n('cancel')}</button>
                  <button disabled={this.state.disabled} className='btn-primary' onClick={this.sendFeedback}>{i18n('send_feedback')}</button>
                </div>
              </div>
            }

          { !success && <button className="btn-second btn-feedback-ai-content" onClick={this.openDialog}>{i18n('provide_ai_feedback')}</button> }
          { success && <div className="review_feedback show">{i18n('thank_you_for_your_review')}</div> }

          </div>
        }

      </div>
    );
  }
}

export default Skeleton;

import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

function getLanguageBasedOnDomain() {
  return window.location.hostname.split('.').pop();
}

var preferredLanguage = localStorage.getItem("preferredLanguage");
var lang = preferredLanguage || getLanguageBasedOnDomain() || "en";

var urlparameter = URLSearchParams && new URLSearchParams(window.location.search);
if (urlparameter) {
  var urlParameterValue = urlparameter.get('lang');
  if (urlParameterValue) lang = urlParameterValue;
}

if (lang.indexOf("de") >= 0 || lang.indexOf("at") >= 0 || lang.indexOf("ch") >= 0) {
  lang = "de";
} else if (lang.indexOf("en") >= 0) {
  lang = "en";
} else if (lang.indexOf("es") >= 0) {
  lang = "es";
} else if (lang.indexOf("nl") >= 0 || lang.indexOf("be") >= 0) {
  lang = "nl";
} else if (lang.indexOf("ro") >= 0) {
  lang = "ro";
} else if (lang.indexOf("cs") >= 0 || lang.indexOf("cz") >= 0) {
  lang = "cs";
} else if (lang.indexOf("sk") >= 0) {
  lang = "sk";
} else if (lang.indexOf("sl") >= 0) {
  lang = "sl";
} else if (lang.indexOf("fr") >= 0) {
  lang = "fr";
} else if (lang.indexOf("is") >= 0) {
  lang = "is";
} else if (lang.indexOf("no") >= 0) {
  lang = "no";
} else if (lang.indexOf("fi") >= 0) {
  lang = "fi";
} else {
  lang = "de";
}

document.documentElement.setAttribute('lang', lang);
localStorage.setItem("preferredLanguage", lang);
document.title = "PPL Trainer APP";
ReactDOM.render(<App/>, document.getElementById('root')
);

serviceWorker.unregister();

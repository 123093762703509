import React, { Component } from 'react';
import _ from 'underscore';
import { Icon, Modal } from 'semantic-ui-react';

import i18n from '../../../utils/i18n';

class ScenarioFormular extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      foundedElements: [],
    };

    this.onSearch = this.onSearch.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.collectAnswers = this.collectAnswers.bind(this);
  }

  getFlatQuestionKeysInGivenLanguage() {
    const lang = document.documentElement.lang;
    return (this.props.answers &&  this.props.answers[lang] && Object.keys(this.props.answers[lang])) || []; 
  }

  collectAnswers(item) {
    const answers = [];
    const correctAnswer = item[item["Correct"]];
    answers.push(item["A"]);
    answers.push(item["B"]);
    answers.push(item["C"]);
    answers.push(item["D"]);
    const index = answers.indexOf(correctAnswer);
    if (index !== -1) {
      answers.splice(index, 1);
      answers.unshift(correctAnswer);
    }
    return answers;
  }

  onSearch(options) {
    const { value } = options.target;
    this.setState({ searchTerm: value });
    this.fetchData(value);
  }

  fetchData(term) {
    const foundedElements = [];
    const flatQuestionKeysInGivenLanguage = this.getFlatQuestionKeysInGivenLanguage();
    _.each(this.props.questions, (key) => {
      _.each(key, (item) => {
        _.each(item.data, function(element) {
          let question = element.question || element["Question"] || "";
          let questionID = element["Question ID"] || element["key"] || "";
          let questionItem = question.match(new RegExp(term, 'i')) || questionID.match(new RegExp(term, 'i'));
          if (flatQuestionKeysInGivenLanguage.indexOf(questionID) === -1 && questionItem) {
            foundedElements.push(element);
          }
        });
      });
    });
    this.setState({ foundedElements });
  }

  render() {
    const that = this;
    return <div className='add-scenario-question-container'>

      <Modal.Header>{i18n("select_question")}</Modal.Header>
      
      <div className='result-list-container'>
        <div className={`fbl mm-search input-field-new-answer height-fix-scenario`}>
          <input type="search" value={this.state.searchTerm} placeholder={i18n("search_questions")} onChange={this.onSearch} />
          <Icon name="search" title={i18n("search")} />
        </div>

        <div className='result-content set-new-answer-assistance'>
          <ul>
            {
              this.state.searchTerm && 
              this.state.searchTerm.length > 2 && 
                _.map(this.state.foundedElements, function (item, index) {
                  let question = item.question || item["Question"] || "";
                  let questionID = item["Question ID"] || item["key"] || index;
                  let readableKey = item["Question ID"] || i18n('flightschool');
                  const correctAnswerIndex = item["correctAnswer"] || 0;
                  const answers = item.answers || that.collectAnswers(item);
                  const correctAnswer = answers[correctAnswerIndex];
                  const image = item.img;
                  return <li key={index + "_" + questionID} className="question-answer-item" onClick={() => that.props.onSelectQuestion({ question, questionID, readableKey, correctAnswerIndex, answers, correctAnswer, image })}>
                    <div className='question-item'>{question} <small>({readableKey})</small></div>
                    <div className='answers-containers correctAnswer'>{correctAnswer}</div>
                    <button className='btn btn-second'>{i18n('select_question')}</button>
                  </li>;
                })
            }
          </ul>
        </div>
      </div>

      <div className="actions fixed-action-layout">
        <button onClick={() => this.props.onCancle()} className="btn btn-second">
          {i18n("cancel")}
        </button>
      </div>
    </div>
  }
}

export default ScenarioFormular;
